import React from 'react';
import './about.css';
import Container from 'react-bootstrap/Container';

function About() {
    return (
        <div className={"about-wrapper"}>
            <Container id={'ueber-uns'}>
                <div className="h1">Über Uns</div>

                <div className="about-flex-container">

                    <div>
                        <img src={"/assets/Geschäftsfuehrer.jpg"} alt={"Geschäftsführer"}/>
                    </div>

                    <div className="about-text fs-5">
                        Wir stehen für Qualität, Zuverlässigkeit und individuelle Betreuung. Mit langjähriger Erfahrung und Leidenschaft für unser Handwerk bieten wir maßgeschneiderte Lösungen für Ihre Projekte. Von Renovierungsarbeiten und Reparaturen bis hin zu Velux Fenster Montagen, Wartungen und Reparaturen – sind wir für Sie da, um Ihre Ideen umzusetzen. Erfahren Sie mehr über uns und unsere Arbeit. Wir freuen uns darauf, Ihnen bei Ihrem nächsten Projekt zur Seite zu stehen!
                    </div>

                </div>
            </Container>
        </div>
    );
}

export default About;
