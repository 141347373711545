import React from 'react';
import Container from 'react-bootstrap/Container';
import "./services.css";
import Card from 'react-bootstrap/Card';

function Services() {
    return (
        <Container id={'dienstleistungen'}>
            <div className="h1">Dienstleistungen</div>
            <div className={"services-container fs-5"}>
                <div className={"services-text"}>
                    <Card className={"services-card"}>
                        <Card.Header>
                            <b>Velux Fenster Montage und Wartung:</b>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Wir bieten eine präzise <b>Installation</b> und
                                regelmäßige <b>Wartung</b> von <b>Velux Fenstern</b>. Genießen Sie optimalen
                                Lichteinfall und
                                verbesserte Belüftung in Ihren Räumen.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className={"skylight"}></Card.Footer>
                    </Card>
                </div>
                <div className={"services-text"}>
                    <Card className={"services-card"}>
                        <Card.Header>
                            <b>Dachreparaturen und Leckortung:</b>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Ein undichtes Dach kann schwerwiegende Folgen haben. Wir
                                stehen bereit, um schnell und effizient <b>Reparaturen</b> durchzuführen
                                und <b>Lecks</b> präzise
                                zu <b>lokalisieren</b>, bevor größere Schäden entstehen.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className={"roof-repair"}></Card.Footer>
                    </Card>
                </div>
                <div className={"services-text"}>
                    <Card className={"services-card"}>
                        <Card.Header>
                            <b>Gründach Pflege:</b>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Grüne Dächer sind nicht nur ästhetisch ansprechend, sondern tragen auch zur
                                Verbesserung der Umwelt bei. Wir kümmern uns um die <b>fachgerechte Pflege</b> Ihres
                                Gründachs,
                                damit
                                es langfristig seine Funktion erfüllt.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className={"green-roof"}></Card.Footer>
                    </Card>
                </div>
                <div className={"services-text"}>
                    <Card className={"services-card"}>
                        <Card.Header>
                            <b><b>Dachwartung:</b></b>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Ein intaktes Dach ist essentiell für den Schutz Ihrer Immobilie. Wir bieten
                                professionelle <b>Dachwartungsdienste</b>, um Schäden frühzeitig zu erkennen und zu
                                beheben, und so
                                die Lebensdauer Ihres Daches zu verlängern.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className={"maintenance"}></Card.Footer>
                    </Card>
                </div>
                <div className={"services-text"}>
                    <Card className={"services-card"}>
                        <Card.Header>
                            <b>Regenrinnenreinigung:</b>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Funktionierende Regenrinnen sind entscheidend, um Regenwasser effizient
                                abzuleiten und Schäden am Gebäude zu verhindern. Wir <b>reinigen</b> Ihre Regenrinnen
                                gründlich,
                                um Verstopfungen zu beseitigen und einen reibungslosen Abfluss zu gewährleisten.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className={"gutter-cleaning"}></Card.Footer>
                    </Card>
                </div>
                <div className={"services-text"}>
                    <Card className={"services-card"}>
                        <Card.Header>
                            <b>Tauben Abwehr:</b>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Tauben können nicht nur eine Belästigung sein, sondern auch Schäden an Ihrem Gebäude
                                verursachen. Wir bieten verschiedene Methoden zur <b>Taubenabwehr</b> an, um Ihr Gebäude
                                zu schützen und unerwünschte Vögel fernzuhalten.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className={"pigot-defense"}></Card.Footer>
                    </Card>
                </div>
            </div>
        </Container>
    );
}

export default Services;
