import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import MyNav from "./components/nav/MyNav";
import Imprint from "./components/imprint/imprint";
import Privacy from "./components/privacy/privacy";
import Footer from "./components/footer/footer";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter([
    {
        path: "/",
        element: <App></App>,
        errorElement: <App></App>,
    },
    {
        path: "impressum",
        element:
            <div>
                <MyNav></MyNav>
                <Imprint></Imprint>
                <Footer></Footer>
            </div>,
    },
    {
        path: "datenschutzerklaerung",
        element:
            <div>
                <MyNav></MyNav>
                <Privacy></Privacy>
                <Footer></Footer>
            </div>,
    },
]);
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
