import React from 'react';
import './home.css';
import Container from 'react-bootstrap/Container';

function Home() {
    return (
        <div className="home-wrapper">
            <Container id={'home'} className="home-height">
                <div>
                    <h1 className="h1 text-center text-white home-h1">Handwerker  |  Montagen  |  Reparaturen</h1>
                    <h2 className={"h2 text-white text-center"}>Fürstenfeldbruck, München und Umgebung</h2>
                    <div className="home-text text-white fs-5 text-center">Auf der Suche nach vielseitigen Handwerkern? Wir bieten professionelle Montagen, Reparaturen und allgemeine Handwerkertätigkeiten für Dächer, Fenster und Fassaden an. Von der Installation bis zur Instandhaltung stehen wir Ihnen zur Verfügung.</div>
                </div>
            </Container>
        </div>
    );
}

export default Home;
