import React from 'react';
import Container from 'react-bootstrap/Container';

function Imprint() {
    return (
            <Container>
                <div className={"fs-5"}>
                    <h1 className={"h1"}>Impressum</h1>

                    <p>Benedikt Rukavina<br />
                        Bautenschutz Rukavina (Einzelunternehmer)<br />
                        Volkenschwander Str. 5A<br />
                        84106 Volkenschwand<br />
                        Deutschland</p>

                    <h2 className={"h2"}>Kontakt</h2>
                    <p>Telefon: +49 (0)17670836573<br />
                        E-Mail: Bautenschutz.Rukavina@gmail.com</p>

                    <h2 className={"h2"}>Umsatzsteuer-ID</h2>
                    <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                        DE75209843129</p>

                    <h2 className={"h2"}>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
                    <p><strong>Name und Sitz des Versicherers:</strong><br />
                        LVM Rechtsschutz-Service GmbH<br />
                        Kolde-Ring 21<br />
                        48151 Münster</p>
                    <p><strong>Geltungsraum der Versicherung:</strong><br />Deutschland</p>

                    <h2 className={"h2"}>EU-Streitschlichtung</h2>
                    <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

                    <h2 className={"h2"}>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                    <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
                </div>
            </Container>
    );
}

export default Imprint;
