import React from 'react';
import './App.css';
import MyNav from "./components/nav/MyNav";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./components/home/home";
import About from "./components/about/about";
import Services from "./components/services/services";
import Contact from "./components/contact/contact";
import Footer from "./components/footer/footer";

function App() {
    return (
        <div>
            <MyNav></MyNav>
            <Home></Home>
            <About></About>
            <Services></Services>
            <Contact></Contact>
            <Footer></Footer>
        </div>
    );
}

export default App;
