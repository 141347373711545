import React from 'react';
import './contact.css';
import Container from 'react-bootstrap/Container';
import Card from "react-bootstrap/Card";

function Contact() {
    return (
        <Container id={'kontakt'}>
            <div className="h1">Kontakt</div>
            <div className={"contact-flex-container"}>
                <div className={"fs-5 contact-text contact-text-left"}>
                    <p>Kontaktieren Sie uns noch heute, und lassen Sie uns gemeinsam daran arbeiten, Ihre Ziele zu erreichen.</p>
                    <p>Sie können uns telefonisch erreichen oder schreiben Sie uns eine E-Mail.</p>
                    <p>Wir werden uns bei Ihnen melden, um Ihre Fragen zu beantworten und Ihnen weiterzuhelfen.</p>
                </div>
                <div className={"contact-text fs-5"}>
                    <Card className={"contact-card"}>
                        <Card.Header>
                            <b>Kontaktieren Sie uns!</b>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <div><b>Öffnungszeiten:</b></div>
                                <div>Montag - Freitag 8:00 - 18:00 Uhr</div>
                                <div><b>Telefonnummer:</b></div>
                                <div><a href="tel: +4917670836573">+49 (0)17670836573</a></div>
                                <div><b>E-Mail:</b></div>
                                <div><a href="mailto: Bautenschutz.Rukavina@gmail.com">Bautenschutz.Rukavina@gmail.com</a></div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </Container>
    );
}

export default Contact;
