import React from 'react';
import {Link} from "react-router-dom";

function Footer() {
    return (
        <div className="page-footer font-small blue pt-4">
            <div className="container-fluid text-center text-md-left">
                <div className="row">
                    <hr/>
                    <div className="col mb-md-0 mb-5">
                        <h5 className="text-uppercase">Bautenschutz Rukavina</h5>
                        <ul className="list-unstyled">
                            <li><Link to="/#home" className={"nav-link"}>Home</Link></li>
                            <li><Link to="/#ueber-uns" className={"nav-link"}>Über Uns</Link></li>
                            <li><Link to="/#dienstleistungen" className={"nav-link"}>Dienstleistungen</Link></li>
                            <li><Link to="/#kontakt" className={"nav-link"}>Kontakt</Link></li>
                            <li><Link to="/impressum" className={"nav-link"}>Impressum</Link></li>
                            <li><Link to="/datenschutzerklaerung" className={"nav-link"}>Datenschutzerklärung</Link></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="footer-copyright text-center py-3">© 2024 Copyright: <a href="https://Bautenschutz-Rukavina.de/">Bautenschutz-Rukavina.de</a>
            </div>
        </div>
    );
}

export default Footer;
