import React from "react";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {Link} from "react-router-dom";
import ScrollToAnchor from "../scrollToAnchor/ScrollToAnchor";


function MyNav() {
    return (
        <div>
            <ScrollToAnchor></ScrollToAnchor>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand><Link to="/" className={"nav-link"}>
                        <img src={"/assets/Artboard 1.svg"}
                             alt={"Unternehmenslogo"} height="30rem"
                             width="30rem"/> Bautenschutz Rukavina</Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link><Link to="/#home" className={"nav-link"}>Home</Link></Nav.Link>
                            <Nav.Link><Link to="/#ueber-uns" className={"nav-link"}>Über Uns</Link></Nav.Link>
                            <Nav.Link><Link to="/#dienstleistungen"
                                            className={"nav-link"}>Dienstleistungen</Link></Nav.Link>
                            <Nav.Link><Link to="/#kontakt" className={"nav-link"}>Kontakt</Link></Nav.Link>
                            <Nav.Link><Link to="/impressum" className={"nav-link"}>Impressum</Link></Nav.Link>
                            <Nav.Link><Link to="/datenschutzerklaerung"
                                            className={"nav-link"}>Datenschutzerklärung</Link></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default MyNav;
